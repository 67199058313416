var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入skuId" },
                    model: {
                      value: _vm.searchInfo.ID,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "ID", $$v)
                      },
                      expression: "searchInfo.ID"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "供应商" } },
                [
                  _c("sc-type", {
                    attrs: { scType: _vm.searchInfo.scType },
                    on: {
                      "update:scType": function($event) {
                        return _vm.$set(_vm.searchInfo, "scType", $event)
                      },
                      "update:sc-type": function($event) {
                        return _vm.$set(_vm.searchInfo, "scType", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关键词" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品标题/编码" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择商品状态" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    _vm._l(_vm.statusOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "type", $$v)
                        },
                        expression: "searchInfo.type"
                      }
                    },
                    _vm._l(_vm.typeoptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0
                    },
                    model: {
                      value: _vm.searchInfo.stock,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "stock", $$v)
                      },
                      expression: "searchInfo.stock"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.changeTimeSelect },
                    model: {
                      value: _vm.createtime,
                      callback: function($$v) {
                        _vm.createtime = $$v
                      },
                      expression: "createtime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exportSku }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "ID", width: "60px" }
          }),
          _c("el-table-column", {
            attrs: { label: "图片", prop: "logo", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px" },
                      attrs: { src: scope.row.logo }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "编码", prop: "code", width: "80px" }
          }),
          _c("el-table-column", { attrs: { label: "标题", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "供应商", prop: "scType", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.filterDict(scope.row.scType, "sctype")) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "库存", prop: "stock", width: "80px" }
          }),
          _c("el-table-column", {
            attrs: { label: "上游进货价", prop: "scCostPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.scCostPrice) + " 元 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "客户成本价", prop: "costPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.costPrice) + " 元 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "建议零售价", prop: "salePrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.salePrice) + " 元 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "面值", prop: "facePrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.facePrice) + " 元 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createdAt)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updatedAt)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("上架")
                        ])
                      : scope.row.status === -1
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("下架")
                        ])
                      : scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("售罄")
                        ])
                      : scope.row.status === 3
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("下架(上游)")
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未知")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "table-button",
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.updateEquitySku(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        scope.row.status != -1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "table-button",
                                attrs: { size: "small", type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.downSale(scope.row)
                                  }
                                }
                              },
                              [_vm._v("下架")]
                            )
                          : _vm._e(),
                        scope.row.status == -1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "table-button",
                                attrs: { size: "small", type: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.onSale(scope.row)
                                  }
                                }
                              },
                              [_vm._v("上架")]
                            )
                          : _vm._e(),
                        scope.row.scType == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "table-button",
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.addStock(scope.row)
                                  }
                                }
                              },
                              [_vm._v("补货")]
                            )
                          : _vm._e(),
                        scope.row.scType == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "table-button",
                                attrs: { size: "small", type: "warning" },
                                on: {
                                  click: function($event) {
                                    return _vm.getStock(scope.row)
                                  }
                                }
                              },
                              [_vm._v("库存详情")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeStockDialog,
            visible: _vm.dialogSkuStockVisible,
            title: "SKU补货"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSkuStockVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "stockform",
              attrs: {
                model: _vm.stockFormData,
                "label-position": "right",
                "label-width": "100px",
                rules: _vm.stockForm
              }
            },
            [
              _vm.stockFormData.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "数量", prop: "count" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入商品数量",
                          maxlength: "4",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.stockFormData.count,
                          callback: function($$v) {
                            _vm.$set(_vm.stockFormData, "count", $$v)
                          },
                          expression: "stockFormData.count"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.stockFormData.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "过期时间", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择过期时间"
                        },
                        model: {
                          value: _vm.stockFormData.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.stockFormData, "endTime", $$v)
                          },
                          expression: "stockFormData.endTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.stockFormData.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "券码类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.stockFormData.couponType,
                            callback: function($$v) {
                              _vm.$set(_vm.stockFormData, "couponType", $$v)
                            },
                            expression: "stockFormData.couponType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("链接")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("图片")
                          ]),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("卡密")
                          ]),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("卡号+卡密")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.stockFormData.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "文件上传" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.imageAction,
                            "before-upload": _vm.imageBeforeUpload,
                            "on-success": _vm.handleFileSuccess,
                            "file-list": _vm.fileList
                          }
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          )
                        ],
                        1
                      ),
                      _vm.stockFormData.couponType == 1
                        ? _c(
                            "el-link",
                            {
                              staticClass: "template-download",
                              attrs: {
                                type: "primary",
                                href:
                                  "http://rebate-robot.oss-cn-hangzhou.aliyuncs.com/template/link.xlsx",
                                target: "_blank"
                              }
                            },
                            [_vm._v("模版下载")]
                          )
                        : _vm.stockFormData.couponType == 2
                        ? _c(
                            "el-link",
                            {
                              staticClass: "template-download",
                              attrs: {
                                type: "primary",
                                href:
                                  "http://rebate-robot.oss-cn-hangzhou.aliyuncs.com/template/image.xlsx",
                                target: "_blank"
                              }
                            },
                            [_vm._v("模版下载")]
                          )
                        : _vm.stockFormData.couponType == 3
                        ? _c(
                            "el-link",
                            {
                              staticClass: "template-download",
                              attrs: {
                                type: "primary",
                                href:
                                  "http://rebate-robot.oss-cn-hangzhou.aliyuncs.com/template/password.xlsx",
                                target: "_blank"
                              }
                            },
                            [_vm._v("模版下载")]
                          )
                        : _vm.stockFormData.couponType == 4
                        ? _c(
                            "el-link",
                            {
                              staticClass: "template-download",
                              attrs: {
                                type: "primary",
                                href:
                                  "http://rebate-robot.oss-cn-hangzhou.aliyuncs.com/template/number_password.xlsx",
                                target: "_blank"
                              }
                            },
                            [_vm._v("模版下载")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeStockDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.stockConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeStockListDialog,
            visible: _vm.dialogStockListVisible,
            title: "库存详情",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogStockListVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "search-term" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, model: _vm.searchStockInfo }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { filterable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.searchStockInfo.status,
                            callback: function($$v) {
                              _vm.$set(_vm.searchStockInfo, "status", $$v)
                            },
                            expression: "searchStockInfo.status"
                          }
                        },
                        _vm._l(_vm.stockStatusOption, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "过期时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.searchStockInfo.time_range,
                          callback: function($$v) {
                            _vm.$set(_vm.searchStockInfo, "time_range", $$v)
                          },
                          expression: "searchStockInfo.time_range"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.stockSearch }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-button", { on: { click: _vm.StockSearchReset } }, [
                        _vm._v("重置")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.stockExportBatch }
            },
            [_vm._v("批量导出")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.stockExportList }
            },
            [_vm._v("导出记录")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.stockLoading,
                  expression: "stockLoading"
                }
              ],
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: {
                data: _vm.stockData,
                border: "",
                height: "300",
                stripe: "",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "券ID", prop: "ID", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "兑换信息", prop: "info" }
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "status", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status === 0
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("售卖中")
                            ])
                          : scope.row.status === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已售出")
                            ])
                          : scope.row.status === 2
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("冻结")
                            ])
                          : scope.row.status === 3
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("已导出")
                            ])
                          : scope.row.status === 4
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已过期")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未知")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "过期时间", prop: "expireTime", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(scope.row.expireTime))
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center" },
            attrs: {
              "current-page": _vm.searchStockInfo.page,
              "page-size": _vm.searchStockInfo.pageSize,
              "page-sizes": [10, 30, 50, 100],
              total: _vm.stockTotal,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.searchStockInfo, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.searchStockInfo, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.searchStockInfo, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.searchStockInfo, "pageSize", $event)
              },
              "current-change": _vm.stockSearch,
              "size-change": _vm.stockSearch
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogStockExportVisible,
            title: "批量导出",
            width: "35%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogStockExportVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "100px", size: "mini" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "导出数量:" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "62.5%" },
                      attrs: {
                        type: "number",
                        placeholder:
                          "当前符合条件的券有" + _vm.stockAvailableCount + "条"
                      },
                      model: {
                        value: _vm.stockExportCount,
                        callback: function($$v) {
                          _vm.stockExportCount = _vm._n($$v)
                        },
                        expression: "stockExportCount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("条")])],
                    2
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        color: "rgba(0, 0, 0, 0.45)",
                        "margin-top": "10px"
                      }
                    },
                    [_vm._v("售卖中且过期时间近的优先导出")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    loading: _vm.stockExportSubmitting
                  },
                  on: { click: _vm.handleStockExportSubmit }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.dialogStockExportVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出记录",
            visible: _vm.dialogStockExportRecordVisible,
            width: "45%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogStockExportRecordVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.exportLoading,
                  expression: "exportLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.exportData,
                size: "small",
                "show-header": false
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "CreatedAt", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(scope.row.CreatedAt))
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", { attrs: { prop: "name" } }),
              _c("el-table-column", {
                attrs: { width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status === 2
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("成功")
                            ])
                          : row.status === 0
                          ? _c("el-tag", [_vm._v("下载中")])
                          : row.status === 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("导出失败")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未知")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleStockExportRetry(row)
                                  }
                                }
                              },
                              [_vm._v("重新导出")]
                            )
                          : row.status === 2
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "info",
                                  href: row.path,
                                  underline: false
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "text" } },
                                  [_vm._v("下载文件")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center" },
            attrs: {
              "current-page": _vm.searchExportInfo.page,
              "page-size": _vm.searchExportInfo.pageSize,
              "page-sizes": [10, 30, 50, 100],
              total: _vm.exportTotal,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.searchExportInfo, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.searchExportInfo, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.searchExportInfo, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.searchExportInfo, "pageSize", $event)
              },
              "current-change": _vm.stockExportSearch,
              "size-change": _vm.stockExportSearch
            }
          })
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          ref: "refDrawer",
          attrs: {
            title: "sku编辑",
            "append-to-body": "",
            "destroy-on-close": "",
            "before-close": _vm.handleClose,
            visible: _vm.dialog,
            size: "80%",
            "custom-class": "sku-edit-drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _c(
                "el-form",
                {
                  ref: "skuform",
                  attrs: {
                    model: _vm.formData,
                    "label-position": "right",
                    "label-width": "100px",
                    rules: _vm.skuRule
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            disabled: ""
                          },
                          model: {
                            value: _vm.formData.type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type"
                          }
                        },
                        _vm._l(_vm.typeoptions, function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商" } },
                    [
                      _c("sc-type", {
                        attrs: { scType: _vm.formData.scType, disabled: "" },
                        on: {
                          "update:scType": function($event) {
                            return _vm.$set(_vm.formData, "scType", $event)
                          },
                          "update:sc-type": function($event) {
                            return _vm.$set(_vm.formData, "scType", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "必填",
                          maxlength: "20",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "编码:", prop: "code" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder:
                            "必填,上游对商品的唯一标识或我们对自营商品的唯一标识",
                          disabled: ""
                        },
                        model: {
                          value: _vm.formData.code,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "code", $$v)
                          },
                          expression: "formData.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片:" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.imageAction,
                            "show-file-list": false,
                            "before-upload": _vm.imageBeforeUpload,
                            "on-success": _vm.handleAvatarSuccess
                          }
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      ),
                      _c("span", { staticClass: "help" }, [
                        _vm._v("尺寸400*400, 建议大小200kb")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "最大回调时间（min）",
                        prop: "callback_time"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "必填" },
                        model: {
                          value: _vm.formData.callback_time,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "callback_time", _vm._n($$v))
                          },
                          expression: "formData.callback_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "面值:",
                                prop: "facePrice",
                                maxlength: 10,
                                "show-word-limit": ""
                              }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: "",
                                    clearable: "",
                                    placeholder: "必填，最多3位小数"
                                  },
                                  model: {
                                    value: _vm.formData.facePrice,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "facePrice", $$v)
                                    },
                                    expression: "formData.facePrice"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上游进货价:",
                                prop: "scCostPrice",
                                maxlength: 10,
                                "show-word-limit": ""
                              }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: "",
                                    clearable: "",
                                    placeholder: "必填，最多3位小数"
                                  },
                                  model: {
                                    value: _vm.formData.scCostPrice,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "scCostPrice", $$v)
                                    },
                                    expression: "formData.scCostPrice"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "建议零售价:", prop: "salePrice" }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: "",
                                    clearable: "",
                                    placeholder: "必填，最多3位小数",
                                    maxlength: 10,
                                    "show-word-limit": ""
                                  },
                                  model: {
                                    value: _vm.formData.salePrice,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "salePrice", $$v)
                                    },
                                    expression: "formData.salePrice"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户成本价:",
                                prop: "costPrice",
                                maxlength: 10,
                                "show-word-limit": ""
                              }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: "",
                                    clearable: "",
                                    placeholder: "必填，最多3位小数"
                                  },
                                  model: {
                                    value: _vm.formData.costPrice,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "costPrice", $$v)
                                    },
                                    expression: "formData.costPrice"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货号:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择货号",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.getArtNoList
                          },
                          model: {
                            value: _vm.formData.artNoId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "artNoId", $$v)
                            },
                            expression: "formData.artNoId"
                          }
                        },
                        _vm._l(_vm.artNoes, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.ID + "-" + item.name))
                              ])
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            color: "#86888b",
                            "margin-left": "10px"
                          },
                          on: { click: _vm.addArtNo }
                        },
                        [_vm._v("添加货号")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品说明:" } },
                    [
                      _c("editor", {
                        model: {
                          value: _vm.formData.instruction,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "instruction", $$v)
                          },
                          expression: "formData.instruction"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用须知:" } },
                    [
                      _c("editor", {
                        model: {
                          value: _vm.formData.useNotice,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "useNotice", $$v)
                          },
                          expression: "formData.useNotice"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.skuType == 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品关联", prop: "selected" } },
                            [
                              _c("el-transfer", {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.queryShops,
                                  titles: ["全部店铺", "关联店铺"],
                                  "filter-placeholder": "请输入店铺名",
                                  data: _vm.shops
                                },
                                model: {
                                  value: _vm.formData.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "selected", $$v)
                                  },
                                  expression: "formData.selected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c("el-button", { on: { click: _vm.cancle } }, [
                        _vm._v("取 消")
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.doUpdate }
                        },
                        [_vm._v("更 新")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeArtDialog,
            visible: _vm.dialogArtFormVisible,
            title: "创建货号"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogArtFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "artNoForm",
              attrs: {
                model: _vm.artFormData,
                "label-position": "right",
                "label-width": "80px",
                rules: _vm.artNoRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货名:", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入货名",
                      maxlength: "15",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.artFormData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.artFormData, "name", $$v)
                      },
                      expression: "artFormData.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeArtDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doAddAtrNo } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }